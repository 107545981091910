import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createClient, authenticate } from '/@shared/plugins/auth0';
import '/@shared/vendor.js';
import '/@shared/styles/main.css';
import Index from './index.vue';

const app = createApp(Index);

const pinia = createPinia();

app.use(pinia);

createClient();

authenticate(() => {
  app.mount('#app');
});
