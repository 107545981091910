<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '/@features/user';
import { hasRole } from '/@tools/general-utils';
import { Role } from '/@shared/types/ids';
import { logout } from '/@shared/plugins/auth0';
import { useAppStore } from '/@shared/app/app.store';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const appStore = useAppStore();

const loading = ref(0);

const items = computed(() => {
  return appStore.moduleLinks.filter(
    ({
      id,
      technicalName,
      role,
    }: {
      id: number;
      technicalName: string | string[];
      role?: string;
    }) => {
      // time/ressurs shares same object
      return Array.isArray(technicalName)
        ? technicalName.some((n) => user.value.modules.some((m) => n === m.name))
        : user.value.modules.some((m) => m.name === technicalName) &&
            (role ? user.value.roles.some((r) => r.name === role) : true) &&
            !user.value.hiddenModuleIds.includes(id);
    },
  );
});

async function initialize() {
  loading.value++;

  return userStore
    .loadUser()
    .then(() => {
      if (items.value.length === 1) {
        location.href = items.value[0].link;
      }
    })
    .catch((err) => {
      if (err === 'azure-error') {
        location.href = '/error-ad';
      }
    })
    .finally(() => {
      loading.value--;
    });
}

initialize();
</script>

<template>
  <div class="modules">
    <header>
      <div>{{ user.name }}</div>

      <a href="/admin" v-if="hasRole(user, [Role.Admin])">Admin</a>

      <button v-on:click="logout()">Logg ut</button>
    </header>

    <h1>
      <img src="/images/dokflyt-logo-h.svg" alt="Dokflyt moduler" />

      moduler
    </h1>

    <div class="loading-icon" v-if="loading"></div>

    <nav class="modules__nav">
      <a class="module-link" v-for="mod in items" :href="mod.link" :key="mod.name">
        <img :src="mod.icon" alt="" class="module-link__thumb" />
        {{ mod.name }}
      </a>
    </nav>
  </div>
</template>

<style scoped>
@import '/@shared/styles/visuals';

.modules {
  min-height: 100vh;

  background-color: var(--color-bg);

  display: grid;
  grid-template-rows: max-content max-content 1fr;
  place-items: center;
  align-items: flex-start;
  gap: var(--gap-lg);
  padding: var(--gap-md);

  & > *:not(header) {
    max-width: 30rem;
  }
}

header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  align-items: center;
  gap: var(--gap-md);
}

h1 {
  display: flex;
  gap: var(--gap-md);
  align-items: center;
  font-size: 1.5rem;
}

img {
  width: 100%;
  max-width: 200px;
}

.modules__nav {
  @extend %card;
  display: grid;
  width: 100%;
  overflow-y: auto;

  & > * {
    padding: var(--gap-sm);
  }

  & > *:not(:last-child) {
    border-bottom: 1px solid var(--color-divider);
  }
}

.module-link {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: var(--gap-md);
  color: inherit;

  &:hover {
    background-color: var(--color-hover);
  }

  &:hover:first-child {
    border-top-left-radius: var(--radius-sm);
    border-top-right-radius: var(--radius-sm);
  }

  &:hover:last-child {
    border-bottom-left-radius: var(--radius-sm);
    border-bottom-right-radius: var(--radius-sm);
  }
}

.module-link__thumb {
  height: 2rem;
  width: auto;
}

button {
  border: transparent;
  border: 1px solid var(--color-border);
  background-color: transparent;
  padding: var(--gap-sm) var(--gap-md);
  border-radius: var(--radius-sm);

  &:hover {
    background-color: var(--color-hover);
  }
}
</style>
